










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DetailField",
  props: { label: String, data: String },
});
